import React from "react"

import Layout from "../components/layout"
import ImageTextBlock from "../components/image-text-block";
import ImageOne from "../images/why-page-images/a-challange-to-mental-and-physical-wellbeing.png"
import ImageTwo from "../images/why-page-images/addicictive-by-design.png"

import InteriorHeader from "../components/interior-header";
import animation from '../animations/why-hero.json';
import SEO from "../components/seo";
import AboutCta from "../components/about-cta";
import ImageBlockQuery from "../hooks/use-imagetextblock";


const WhyPage = () => {
  return (
    <Layout>
      <SEO title="Why" description="Learn about the benefits of disconnecting from technology"/>
      <InteriorHeader title={`Why Balance?`} pageLocation={`why-interior-hero`}  animation={animation} />
      <section className={`fifty-fifty-image`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {
                /*Looping throught the data*/
                ImageBlockQuery().allWhyTextImageBlockJson.nodes.map((items, index) => (
                  <ImageTextBlock
                    textBlockPosition={items['textPosition']}
                    sectionClassName="why-page-block"
                    sectionTitle={items['sectionTitle']}
                    type="image"
                    imageUrl={index === 0 ? ImageOne : ImageTwo}
                    shape={items['shape']}
                    hasCta='no'>
                    {items['sectionContent']}
                  </ImageTextBlock>
                ))
              }
            </div>
          </div>
        </div>
      </section>

      <section className="benefits">
        <div className="container">
          <div className="benefits__wrapper">
            <div className="row">
              <div className="col-12">
                <div className="benefits__title">
                  BENEFITS
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">

                {
                  /*Looping throught the data*/
                  ImageBlockQuery().allWhyBenefitsJson.nodes.map((items, index) => (
                    <ImageTextBlock
                      textBlockPosition={items['textPosition']}
                      sectionClassName="benefits__item"
                      sectionTitle={items['sectionTitle']}
                      type="image"
                      imageUrl={items['image']}
                      hasCta='yes'
                      ctaOptions={items['cta']}
                      imageSize={items['imageSize']}
                    >
                      {items['sectionContent']}
                    </ImageTextBlock>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <AboutCta hasImage={false}/>

    </Layout>
  )
}
export default WhyPage
